import { inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CanDeactivateFn } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { Observable, map } from 'rxjs';
import { SaveButtonService } from '../save-button.service';

export const hasUnsavedChangesGuard: CanDeactivateFn<any> = (): boolean | Observable<boolean> => {
    const saveButtonService = inject(SaveButtonService);
    const _dialogService = inject(DialogBuilderService);
    const _translocoService = inject(TranslocoService);

    let executeActionSignal = signal<string>('');
    const _translocoContent = toSignal(_translocoService.selectTranslation());

    if (saveButtonService.hasUnsavedChanges()) {
        const dialogRef = _dialogService.openDialog({
            descriptor: {
                dialogSize: 's',
                header: {
                    title: _translocoContent()['confirmDialog.unsavedChanges'],
                    showCloseButton: false,
                },
                content: {
                    icon: {
                        show: true,
                        name: 'heroicons_outline:exclamation-triangle',
                        color: 'warn',
                    },
                    description: _translocoContent()['confirmDialog.unsavedChangesConfirmation'],
                },
                executeActionSignal: executeActionSignal,
                actions: {
                    dialogActions: [
                        {
                            code: 'cancel',
                            style: ButtonType.flat,
                            title: _translocoContent()['buttons.cancel'],
                            dismiss: true,
                        },
                        {
                            code: 'continue',
                            color: 'primary',
                            style: ButtonType.raised,
                            title: _translocoContent()['buttons.yes'],
                        },
                    ],
                },
            },
        });

        return dialogRef.afterClosed().pipe(
            map(value => {
                const selection = executeActionSignal();
                saveButtonService.hasUnsavedChanges = selection === 'cancel';

                let continueWithoutSaving = executeActionSignal() === 'continue';
                saveButtonService.continueWithoutSaving = continueWithoutSaving;
                return continueWithoutSaving;
            })
        );
    }

    saveButtonService.continueWithoutSaving = true;
    return true;
};
